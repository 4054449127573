import Vue from 'vue';
import { initSentry, getEnvVar } from '@utils';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Cloudinarize from '@plugins/cloudinarize';
import parentLogin from '@parent/components/login.vue';
import parentRegister from '@parent/components/register.vue';
import VueRouter from 'vue-router';

Vue.use(Cloudinarize);
Vue.use(VueReCaptcha, { siteKey: getEnvVar('RECAPTCHA_SITE_KEY') });
Vue.use(VueRouter);

document.addEventListener('DOMContentLoaded', () => {
  initSentry();

  if (!document.getElementById('parent-authentication-vue')) {
    return;
  }

  // eslint-disable-next-line no-unused-vars
  const app = new Vue({
    el: '#parent-authentication-vue',
    router: new VueRouter({ mode: 'history' }),
    components: {
      parentLogin,
      parentRegister,
    },
    methods: {
      switchToLogin() {
        $('#registrationModal').modal('hide');
        $('#loginModal').modal('show');
      },
    },
  });
});
