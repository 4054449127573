import { axios, regexPatterns } from '@utils';

export default {
  data() {
    return {
      password: '',
      email: '',
      error: '',
      errorUnknown: false,
      canLogIn: false,
      loading: false,
      emailRegex: regexPatterns.email.regex,
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;

      if (this.canLogIn) {
        await this.login();
      } else {
        await this.runPreLoginCheck();
      }

      this.loading = false;
    },
    async runPreLoginCheck() {
      const actionAfterLogin = $('#loginModal').data('action-after-login');
      axios.post(
        '/login-check',
        { user: { email: this.email.toLowerCase() }, action_after_login: actionAfterLogin },
      ).then((response) => {
        if (response.data.success && response.status === 200) {
          this.canLogIn = true;
        } else {
          this.errorUnknown = true;
        }
      }).catch((error) => this.handleErrorResponse(error));
    },
    async login() {
      axios.post(
        '/login',
        {
          user: { email: this.email.toLowerCase(), password: this.password },
          resp_format: 'json',
        },
      ).then((response) => {
        if (response.data.success && response.status === 200) {
          window.location.href = response.data.redirect_url;
        } else {
          this.errorUnknown = true;
        }
      }).catch((error) => this.handleErrorResponse(error));
    },
    handleErrorResponse(error) {
      if (error.response?.status === 401) {
        this.error = error.response.data.message;
      } else {
        this.errorUnknown = true;
      }
    },
    onEmailChange() {
      if (this.error) {
        this.resetState();
      }
    },
    onModalClose() {
      this.email = '';
      this.password = '';
      this.resetState();
    },
    resetState() {
      this.error = '';
      this.errorUnknown = false;
      this.canLogIn = false;
      this.loading = false;
    },
  },
  computed: {
    submitDisabled() {
      return this.loading
        || this.email.length === 0
        || !this.emailRegex.test(this.email)
        || (this.canLogIn && this.password.length === 0)
        || (!this.canLogIn && this.error.length > 0);
    },
  },
};
