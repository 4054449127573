<template>
  <div class="modal fade login-modal login-signup-modal login-vue"
       id="loginModal" tabindex="-1" role="dialog"
       aria-labelledby="loginModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <button
          type="button"
          class="modal-close green"
          data-dismiss="modal"
          aria-label="Close"
          @click="onModalClose">
        <span class="fal fa-times" />
      </button>

      <div class="modal-content">
        <div class="modal-body">
          <div class="login login-vue">
            <div class="login__content">
              <illustration name="girl"></illustration>
              <h4 class='align-center mt-4'>Sign in to your Kinside account</h4>
              <slot></slot>
              <div class="horizontal-line-with-text">
                <span>OR</span>
              </div>
              <form id='login-form' @submit.prevent="loading = true;submitForm();">
                <k-form-field
                    label="Email"
                    class="form-field"
                    :rules="{email: true, required: true}"
                >
                  <k-input
                      name="email"
                      v-model.trim="email"
                      class="w-100"
                      :ref="email"
                      @change="onEmailChange"
                  >
                  </k-input>
                </k-form-field>
                <div class="mt-3" v-if="canLogIn">
                  <k-form-field
                    label="Password"
                    class="form-field"
                    :rules="'required'"
                  >
                    <k-input
                      name="Password"
                      type="password"
                      v-model.trim="password"
                      class="w-100"
                      :ref="password"
                    >
                    </k-input>
                  </k-form-field>
                  <a href='/forgot'><p>Forgot your password?</p></a>
                </div>
                <box color="rust" class="mt-2" v-if="error.length > 0 || errorUnknown">
                  <p>{{ error }}</p>
                  <p v-if="errorUnknown">
                    Something went wrong. Please contact us directly via
                    <a href='mailto:support@kinside.com'>support@kinside.com</a>
                  </p>
                </box>

                <system-button
                    class="fullWidth"
                    :disabled="submitDisabled"
                    type="submit"
                >
                  Sign in
                </system-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KFormField from '@components/forms/form_field.vue';
import KInput from '@components/inputs/input.vue';
import Illustration from '@components/illustration.vue';
import SystemButton from '@components/buttons/system-button.vue';
import Box from '@components/box.vue';
import ParentAuthMixin from '../mixins/parentAuth';

export default {
  name: 'parent-login',
  components: {
    KFormField,
    KInput,
    Box,
    SystemButton,
    Illustration,
  },
  mixins: [ParentAuthMixin],
  mounted() {
    $('#parent-authentication-vue .login__content__google-sso').show();
  },
};
</script>

<style lang="scss" scoped>
  .horizontal-line-with-text {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--gray-20);
    line-height: 0.1em;
    margin: calc(var(--grid-unit) * 4) 0 calc(var(--grid-unit) * 4);
  }

  .horizontal-line-with-text span {
    background:#fff;
    color: var(--gray-60);
    padding:0 calc(var(--grid-unit) * 2);
  }

  .login__content__google-sso img {
    @media only screen and (max-width: 400px ) {
      margin: 0 calc(var(--grid-unit) * 2) !important;
    }

    @media only screen and (max-width: 360px ) {
      margin: 0 var(--grid-unit) !important;
    }
  }

</style>
